import {combineReducers} from "redux";
import EnterJobForm from "./EnterJobForm";
import GiveTutoringForm from './GiveTutoringForm';
import SearchTutoringForm from './SearchTutoringForm'
import SearchJob from './SearchJob'
import GemeindeErstberatungForm from "./GemeindeErstberatung";

const rootReducer = combineReducers({ EnterJobForm , GiveTutoringForm, SearchTutoringForm, SearchJob, GemeindeErstberatungForm});

export default rootReducer;
