import {baseUrl, apiToken} from "./index";
import {Validation} from "io-ts";
import {BezirkList, GemeindeList} from "../pages/GemeindeErstberatungFormSteps/GemeindeErstberatungData";

const url = baseUrl + "/get-bezirk-list";
const gemeindeUrl = baseUrl + "/get-gemeinde-list";
const submitUrl = baseUrl + "/store-gemeindeberatung";

export const getGemeinden = async (id: string | number): Promise<Validation<GemeindeList>> => {

    const response = await fetch(gemeindeUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization' : 'Bearer '+apiToken
        },

        body: JSON.stringify({bezirk_id: id})
    })

    const data = await response.json();
    return GemeindeList.decode(data);
}

export const fetchData = async (): Promise<Validation<BezirkList>> => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization' : 'Bearer '+apiToken
        },
    })

    const data = await response.json();

    return BezirkList.decode(data)
};

export const submitForm = async (data: { [key: string]: any }) => {

    const form_data = new FormData();
    for (let key in data) {
        form_data.append(key, data[key])
    }

    const response = await fetch(submitUrl, {
        method: 'POST',
        body: form_data,
        headers: {
            'Accept': '*/*',
            'Authorization' : 'Bearer '+apiToken
        },
    });

    return await response.json();
};