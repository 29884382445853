import SearchTutoring from "../constants/SearchTutoring.constants";

export const next = () => ({
    type: SearchTutoring.NEXT
});

export const back = () => ({
    type: SearchTutoring.PREV
});

export const setData = (data: any) => ({
    type: SearchTutoring.SET_DATA,
    payload: data
});

export const setStep = (step: number) => ({
    type: SearchTutoring.SET_STEP,
    payload: step
});

export const setFormValues = (data: any) => ({
    type: SearchTutoring.SET_FORM_VALUES,
    payload: data
})
