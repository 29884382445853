import * as t from 'io-ts'
import {IdTermObj, Step, ValueNameObj} from "../../GeneralTypesIO";

export const Bildungsabschluss = t.type({
    id: t.union([t.string, t.number]),
    name: t.string,
    freetext_allowed: t.union([t.literal(0), t.literal(1), t.boolean])
})

export const Fach = t.type({
    id: t.union([t.string, t.number]),
    fach_name: t.string
})

export const Stufe = t.type({
    id: t.union([t.string, t.number]),
    stufe_name: t.string
})

export const SearchTutoringData = t.type({
    bezirk: t.array(IdTermObj),
    bildungsabschluss: t.array(Bildungsabschluss),
    faecher: t.array(Fach),
    nachhilfeort: t.array(ValueNameObj),
    stufe: t.array(Stufe)
})

export type SearchTutoringData = t.TypeOf<typeof SearchTutoringData>

export type SearchTutoringStep = Step & {
    formValues: SearchTutoringData
}