import React, {FunctionComponent, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

import "react-step-progress-bar/styles.css";
import ProgressStyle from "../../styles/progressbar.module.scss";
import {ProgressBar, Step} from "react-step-progress-bar";
import {Steps, StepsHeading} from "./Steps";
import {connect} from "react-redux";
import {setStep} from "../../redux/actions/EnterJob.actions";

const ProgressBarGemeindeZukunftsWerkstatt: FunctionComponent<any> = ({step, setStep}) => {
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        const percentSteps = 100 / Object.keys(Steps).length;
        setPercent(percentSteps * step);
    }, [step]);

    const setStepPosition = (position: number) => {
        setPercent(position);
    };

    return (
        <ProgressBar percent={percent} filledBackground="linear-gradient(to right, #fefb72, #f0bb31)">
            {Object.keys(Steps).map((key, index) => (
                <Step transition="scale" position={"100%"}>
                    {({
                          accomplished,
                          index,
                          position
                      }: {
                        accomplished: any;
                        index: any;
                        position: any;
                    }) => (
                        <div className={ProgressStyle.progressPoint}>
                            <div
                                onClick={() => setStep(index + 1)}
                                className={`${ProgressStyle.iconWrapper} ${
                                    accomplished ? ProgressStyle.accomplished : ""
                                }`}
                            >
                              {accomplished ? <FontAwesomeIcon icon={faCheck}/> : index + 1}
                                {index + 1 === step ? setStepPosition(position) : ""}
                            </div>
                            <h3 className={ProgressStyle.heading}>{StepsHeading[index + 1]}</h3>
                        </div>
                    )}
                </Step>
            ))}
        </ProgressBar>
    );
};

const mapStateToProps = (state: any) => {
    return {step: state.GemeindeErstberatungForm.step};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setStep: (step: number) => dispatch(setStep(step))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProgressBarGemeindeZukunftsWerkstatt);
