import React, {FunctionComponent} from "react";
import {ErrorMessage, useFormContext} from "react-hook-form";

import HelperStyles from "../styles/helper.module.scss";
import Input from "../styles/input.module.scss";

const InlineRadioButtons: FunctionComponent<{
    options: { label: string, value: any, selected: boolean | 0 | 1 }[];
    name: string;
    title: string;
    required: boolean;
    onChange?: (data: { value: any, id: string }) => void;
    valueParser?: Function
    errorMessage?: string
}> = ({options, name, title, required, onChange, valueParser, errorMessage}) => {
    const {register, errors} = useFormContext();

    const dataOptions = options ? [...options] : null;

    return (
        <div className={`${HelperStyles.mb1}`}>
            <div className={`${HelperStyles.flex} ${HelperStyles.mb1}`}>
                <span>{title}{required ? "*" : ""}</span>

            </div>
            <div className={`${HelperStyles.flex} ${HelperStyles.justifyStart}`}>
                {dataOptions !== null
                    ? dataOptions.map(option => {
                        return (
                            <label key={title + option.value} className={`${HelperStyles.ml2}`}
                                   htmlFor={name + option.value}>
                                <input
                                    defaultChecked={!!option.selected}
                                    ref={register({
                                        required: required ? "Bitte wähle eine Option" : false
                                    })}
                                    type="radio"
                                    name={name}
                                    id={name + option.value}
                                    checked={!!option.selected}
                                    value={valueParser ? valueParser(option.value) : option.value}
                                    onChange={() => {
                                        onChange ? onChange({
                                            value: valueParser ? valueParser(option.value) : option.value,
                                            id: name
                                        }) : void (0)
                                    }}
                                />
                                {option.label}
                            </label>
                        );
                    })
                    : ""}
            </div>
            <ErrorMessage
                errors={errors}
                name={name}

                as={<span className={Input.errorMessage}></span>}
            />
        </div>
    );
};

export default InlineRadioButtons;
