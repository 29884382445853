import React, {useEffect} from "react";
import "./App.css";
import './styles/colors.css';

import NavStyles from './styles/nav.module.scss';
import HelperStyles from './styles/helper.module.scss';


import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";

import EnterJobForm from "./pages/EnterJobForm";
import GiveTutoringFormPage from "./pages/GiveTutoringFormPage";
import SearchTutoringFormPage from "./pages/SearchTutoringFormPage";
import GemeindeErstberatungFormPage from "./pages/GemeindeErstberatungFormPage";
import GemeindeZukunftsWerkstattFormPage from "./pages/GemeindeZukunftsWerkstattFormPage";
import SearchJobs from "./pages/SearchJobs";

const App: React.FC = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.1/iframeResizer.contentWindow.min.js";
        script.async = true;

        document.body.appendChild(script);
    }
    )

return (
    <div className={HelperStyles.background}>
        <Router>

                    <Switch>

                        <Route path='/enterjob'>
                            <EnterJobForm/>
                        </Route>
                        <Route path='/gemeindeerstberatung'>
                            <GemeindeErstberatungFormPage/>
                        </Route>
                        <Route path='/givetutoring'>
                            <GiveTutoringFormPage/>
                        </Route>
                        <Route path='/searchtutoring'>
                            <SearchTutoringFormPage/>
                        </Route>
                        <Route path='/searchjobs'>
                            <SearchJobs/>
                        </Route>
                        <Route path='/gemeindezukunftswerkstatt'>
                            <GemeindeZukunftsWerkstattFormPage/>
                        </Route>
                        <Route path='/'>
                            <nav className={NavStyles.nav}>
                                <Link className={NavStyles.link} to='/enterjob'>EnterJob</Link>
                                <Link className={NavStyles.link} to='/gemeindezukunftswerkstatt'>Gemeinde Zukunftswerkstatt</Link>
                                <Link className={NavStyles.link} to='/gemeindeerstberatung'>Gemeinde Erstberatung</Link>
                                <Link className={NavStyles.link} to='/givetutoring'>Gebe Nachhilfe</Link>
                                <Link className={NavStyles.link} to='/searchtutoring'>Suche Nachhilfe</Link>
                                <Link className={NavStyles.link} to='/searchjobs'>Jobsuche</Link>
                            </nav>
                        </Route>
                    </Switch>
                </Router>
        </div>
    );
};

export default App;
