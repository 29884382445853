export const Steps = {
    1: ['vorname', 'nachname', 'strasse', 'plz', 'ort', 'bevorzugter_bezirk', 'telefon', 'email'],
    2: ['datenschutz'],
    3: []
};

export const StepsHeading = {
    1: 'Kontakt',
    2: 'Sonstiges',
    3: 'Abschluss'
}