import React from "react";

type ShowError = {
    errors: string[]
}
const ShowError: React.FC<ShowError> = ({errors}) => {

    return <>
        {errors.map(error => error)}
    </>
}

export default ShowError;