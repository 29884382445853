import * as t from 'io-ts';
import {IdTermObj, Step, TitleNameObj} from "../../GeneralTypesIO";


export const EnterJobData = t.type({
    beschaftigung: t.array(TitleNameObj),
    bewerbung: t.array(TitleNameObj),
    bezirk: t.array(IdTermObj),
    einsatzbereich: t.array(TitleNameObj),
    zeitraum: t.array(TitleNameObj)
});

export type EnterJobData = t.TypeOf<typeof EnterJobData>

export type EnterJobStep = Step & {formValues: EnterJobData}