import * as t from 'io-ts';

export const TitleNameObj = t.type({
    title: t.string,
    name: t.string
})

export type TitleNameObj = t.TypeOf<typeof TitleNameObj>

export const IdTermObj = t.type({
    id: t.union([t.string, t.number]),
    bezeichnung: t.string
})

export type IdTermObj = t.TypeOf<typeof IdTermObj>

export const ValueNameObj = t.type({
    value: t.union([t.string, t.number]),
    name: t.string
})

export type ValueNameObj = t.TypeOf<typeof ValueNameObj>

export type Step = {
    data: {[key: string]: any},
    setData: (data: Object) => any
}

export const Gemeinde = t.type({
    id: t.union([t.string, t.number]),
    gemeinde_name: t.string,
})

export type Gemeinde = t.TypeOf<typeof Gemeinde>