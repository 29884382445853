import React, {FunctionComponent, useEffect, useState} from "react";
import Loader from 'react-loader';

import {FormContext, useForm} from "react-hook-form";
import {useAlert} from 'react-alert';
import {Steps} from "./GiveTutoringFormSteps/Steps";
import {connect} from "react-redux";
import {back, next, setData} from "../redux/actions/GiveTutoring.actions";

import SiteStyle from "../styles/site.module.scss";
import InputStyle from "../styles/input.module.scss";
import ProgressStyle from "../styles/progressbar.module.scss";

import {fetchGiveTutoringData, submitForm} from '../api/GiveTutoringForm';

import ProgressBarGiveTutoring from "./GiveTutoringFormSteps/ProgressBarGiveTutoring";

import FirstStep from "./GiveTutoringFormSteps/FirstStep";
import SecondStep from "./GiveTutoringFormSteps/SecondStep";
import Finished from "../components/Finished";
import {ERROR_TEXT} from "../Text/text";
import {useSubmit} from "../helpers/UseSubmit";
import useButtons from "../helpers/UseButtons";
import RenderRemoteEither from "../components/RenderRemoteEither";
import {GiveTutoringData} from "./GiveTutoringFormSteps/GiveTutoringData";
import {initial, pending} from "@devexperts/remote-data-ts";
import {failure, RemoteData, success} from "@devexperts/remote-data-ts/es6";
import {Either} from "fp-ts/es6/Either";
import {Errors} from "io-ts";

const EnterJobForm: FunctionComponent<{
    step: number;
    data: any;
    next: any;
    back: any;
    setData: any;
}> = ({step, next, back, setData, data}) => {
    const methods = useForm();

    const alert = useAlert();
    const {sending, onSubmit} = useSubmit({
        showErrorsAction: alert.show,
        formFinished: next,
        submitAction: submitForm,
        data: () => ({...data, suche_type: 'geben', faecher_table: JSON.stringify(data.faecher_table)}),
        errorText: ERROR_TEXT
    })

    const validatedNext = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        const fields: any = Steps;
        const isValid = await methods.triggerValidation(fields[`${step}`]);
        if (isValid) {
            setData(methods.getValues());
            next();
        }
    };

    const validateBack = () => {
        if (step > 1) {
            back();
        }
    };

    const {submitButton, nextButton, prevButton} = useButtons(step, Object.keys(Steps).length)


    const [formValues, setFormValues] = useState<RemoteData<string, Either<Errors, GiveTutoringData>>>(initial)
    useEffect(() => {
        setFormValues(pending)
        fetchGiveTutoringData().then(data => {
            setFormValues(success(data));
        }).catch(e => setFormValues(failure(e)))
    }, []);

    const renderStep = (step: number, formValues: GiveTutoringData) => {
        switch (step) {
            case 1:
                return <FirstStep setData={setData} formValues={formValues} data={data} {...methods} />;

            case 2:
                return <SecondStep setData={setData} formValues={formValues} data={data} {...methods} />;

            case 3:
                return <Finished text={'Danke für deinen Eintrag in unserer Lernbörse'}/>;

            default:
                return <></>
        }
    };

    return (
        <div className={SiteStyle.formContainer}>

                    <div className={ProgressStyle.wrapper}>
                        <ProgressBarGiveTutoring/>
                    </div>
                    <FormContext {...methods}>
                        <form
                            className={InputStyle.form}
                            onSubmit={methods.handleSubmit(onSubmit)}
                        >
                            <RenderRemoteEither showLoadingOnInitial={true} renderStep={renderStep} step={step} formValues={formValues}/>
                            <div className={InputStyle.btnWrapper}>
                                <button
                                    className={InputStyle.btn}
                                    disabled={!prevButton}
                                    onClick={validateBack}
                                >
                                    zurück
                                </button>
                                {submitButton ? (
                                    <button
                                        className={InputStyle.btn}
                                        disabled={!submitButton || sending}
                                        type="submit"
                                    >Senden {sending ? <Loader loaded={false}/> : ''}</button>
                                ) : (
                                    <button
                                        className={InputStyle.btn}
                                        disabled={!nextButton}
                                        onClick={(e) => {
                                            validatedNext(e)
                                        }}
                                    >
                                        Nächster Schritt
                                    </button>
                                )}
                            </div>
                        </form>
                    </FormContext>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        step: state.GiveTutoringForm.step,
        data: state.GiveTutoringForm.data,
        direction: state.GiveTutoringForm.direction
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        next: () => dispatch(next()),
        back: () => dispatch(back()),
        setData: (data: Object) => dispatch(setData(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterJobForm);
