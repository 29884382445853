import React, {FunctionComponent} from "react";
import NormalCheckBox from '../../components/NormalCheckBox';
import TextInput from "../../components/TextInput";

import HelperStyles from "../../styles/helper.module.scss";
import {handleChange} from "../../helpers";

import UserAddNewTutoring from "../../components/UserAddNewTutoring";
import {GiveTutoringStep} from "./GiveTutoringData";

const SecondStep: FunctionComponent<GiveTutoringStep> = ({data, setData, formValues}) => {

    const faecher = formValues.faecher.map(f => ({
        label: f.fach_name,
        value: f.id,
    }));
    const stufen = formValues.stufe.map(s => ({
            label: s.stufe_name,
            value: s.id,
    }));

    const setUserChoices = (choices: unknown, name: string | number) => {
        setData({[`${name}`]: choices})
    };

    return (
        <>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <h2 className={HelperStyles.textLeft}>Fächer</h2>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <UserAddNewTutoring data={data.faecher_table}
                                        handleChange={(choices: unknown, name: string) => setUserChoices(choices, name)}
                                        name={'faecher_table'}
                                        faecher={faecher} stufen={stufen}/>
                </div>
            </div>

            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.erfahrungen}
                        type="text"
                        label="Bisherige Erfahrungen"
                        name="erfahrungen"
                        required={false}
                    /></div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <NormalCheckBox
                        onInputChange={handleChange(setData)}
                        value={parseInt(data.datenschutz) ? 0 : 1}
                        checked={!!parseInt(data.datenschutz)}
                        title=""
                        required={true}
                        name="datenschutz"
                        label={
                            "Mit Teilnahme an der Lernbörse erkläre ich mich mit den <a target='blank' rel='nofollow noopener' href='https://www.jugendservice.at/datenschutz/'>Datenschutzbestimmungen</a> einverstanden. Ich erkläre mich damit einverstanden, dass die in das Online-Formular eingetragenen Daten zum Zweck des Betriebes einer Lernbörse verarbeitet und an Nachfragende übermittelt werden. "
                        }
                        errorMessage={"Bitte akzeptieren Sie die Datenschutzerklärung"}
                    /></div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <NormalCheckBox
                        onInputChange={handleChange(setData)}
                        value={parseInt(data.emailconfirm) ? 0 : 1}
                        checked={!!parseInt(data.emailconfirm)}
                        title=""
                        required={true}
                        name="emailconfirm"
                        label={"Nach dem Absenden des Formulars bekommst du die Zusammenfassung deiner Daten nochmals per Mail zugesandt. Erst nach deiner Bestätigung mittels Link, können wir dein Angebot an interessierte Schülerinnen und Schüler weiterleiten."}
                        errorMessage={"Bitte setzen Sie hier einen Haken"}
                    />
                </div>
            </div>
        </>
    );
};

export default SecondStep;
