import React, {FunctionComponent} from "react";
import {ErrorMessage, useFormContext} from "react-hook-form";

import Input from "../styles/input.module.scss";

import HelperStyles from "../styles/helper.module.scss";

const NormalCheckbox: FunctionComponent<{
    label: string;
    name: string;
    title: string;
    value: any;
    errorMessage: string;
    checked: boolean;
    required: boolean;
    onInputChange: (data: { value: any, id: string }) => any;
}> = ({label, name, checked, onInputChange, title, errorMessage, value, required}) => {
    const {control, errors, register, watch} = useFormContext();

    const mustBeChecked = () => {
        return !!watch(name) || errorMessage
    }

    return (
        <>
            <div>{title}</div>
            <label className={`${Input.textLabel} ${HelperStyles.mb1}`}>
                <input
                    defaultValue={value}
                    // value={value}
                    type="checkbox"
                    ref={register({
                        validate: {
                            mustBeChecked: () => required ? mustBeChecked() : true
                        }
                    })}
                    onChange={() => onInputChange({id: name, value: Number(value) === 0 ? 1 : 0})}
                    defaultChecked={checked}
                    id={name}
                    name={name}
                />
                <span dangerouslySetInnerHTML={{__html: label }}></span>
            </label>
            <ErrorMessage
                name={name}
                errors={errors}
                as={<span className={Input.errorMessage}></span>}
            />
        </>
    );
};

export default NormalCheckbox;
