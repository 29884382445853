import React, {FunctionComponent} from "react";
import NormalCheckBox from '../../components/NormalCheckBox';

import HelperStyles from "../../styles/helper.module.scss";
import {handleChange} from "../../helpers";
import {GemeindeZukunftsWerkstattStep} from "./GemeindeZukunftsWerkstattData";

const ThirdStep: FunctionComponent<GemeindeZukunftsWerkstattStep> = ({data, setData}) => {

    const handleFile = (e) => {
        e.persist();
        const value = e.target.files[0];
        setData({'dokumente[]': value})
    };

    return (
        <>
            <div className={`${HelperStyles.flex} ${HelperStyles.mb2}`}>
                <div className={HelperStyles.wfull}>
                    <NormalCheckBox
                        onInputChange={handleChange(setData)}
                        value={parseInt(data.datenschutzbestimmungen) ? 1 : 0}
                        checked={parseInt(data.datenschutzbestimmungen) ? true : false}
                        title=""
                        required={true}
                        name="datenschutzbestimmungen"
                        label={
                            "Ich erkläre mich mit der <a target='blank' rel='nofollow noopener' href='https://www.jugendservice.at/datenschutz/'>Datenschutzerklärung</a> einverstanden"
                        }
                        errorMessage={"Bitte akzeptieren Sie die Datenschutzerklärung"}
                    />
                </div>
            </div>
            <div className={`${HelperStyles.flex} ${HelperStyles.mb2}`}>
                <div className={HelperStyles.wfull}>
                    <input onChange={(e) => handleFile(e)} name="dokumente[]" id="dokumente" type="file"/>
                </div>
            </div>
        </>
    );
};

export default ThirdStep;
