import {fold, RemoteData} from "@devexperts/remote-data-ts/es6";
import {Either, fold as foldE, left} from "fp-ts/es6/Either";
import {Errors} from "io-ts";
import React from "react";
import {pipe} from "fp-ts/es6/pipeable";
import Loader from "react-loader";
import {flow} from "fp-ts/es6/function";
import ShowError from "./ShowError";
import {PathReporter} from "io-ts/es6/PathReporter";

type renderForm = {
    renderStep: (step: number, formValues: any) => JSX.Element;
    step: number;
    formValues: RemoteData<unknown, Either<Errors, any>>;
    showLoadingOnInitial?: boolean
}
const RenderRemoteEither: React.FC<renderForm> = ({renderStep, step, formValues, showLoadingOnInitial}) => {

    return <>
        {pipe(
            formValues,
            fold(() => showLoadingOnInitial && <Loader loaded={false}/>,
                () => <Loader loaded={false}/>,
                () => <></>,
                flow(foldE((e) => <ShowError errors={PathReporter.report(left(e))}/>,
                    (data) => renderStep(step, data)
                ))
            )
        )}
    </>
};

export default RenderRemoteEither;