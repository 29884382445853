import React from 'react';
import helper from "../styles/helper.module.scss";


type Finished = {
    text: string;
}
const Finished: React.FC<Finished> = ({text}) => {

    return(
        <div className={helper.container}>
            <h2>{text}</h2>
        </div>
    )
}

export default Finished;