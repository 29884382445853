import * as t from 'io-ts'
import {Gemeinde, IdTermObj, Step} from "../../GeneralTypesIO";

export const BezirkList = t.type({
    bezirk_list: t.array(IdTermObj)
});

export type BezirkList = t.TypeOf<typeof BezirkList>

export type GemeindeErstberatungStep = Step & {
    formValues: BezirkList
}

export const GemeindeList = t.type({
    gemeinde_list: t.array(Gemeinde)
})

export type GemeindeList = t.TypeOf<typeof GemeindeList>