import React, {FunctionComponent} from "react";
import TextArea from "../../components/TextArea";
import MultiCheckbox from "../../components/MultiCheckbox";

import HelperStyles from "../../styles/helper.module.scss";
import TextInput from "../../components/TextInput";
import RadioButtons from "../../components/RadioButtons";
import {handleChange} from "../../helpers";
import {EnterJobStep} from "./EnterJobData";
import InlineRadioButtons from "../../components/InlineRadioButtons";
import CustomDropdown, {LabelValueObjDropdown} from "../../components/CustomDropdown";
import AgeComponent from "../../components/AgeComponent";

const ThirdStep: FunctionComponent<EnterJobStep> = ({data, setData, formValues}) => {
    const einsatzbereichOptions = formValues.einsatzbereich.map(e => ({
        label: e.title,
        name: e.name,
        textField: e.name === 'sonstiges' && {
            name: 'sonsttext'
        }
    }))

    const zeitraumOptions = formValues.zeitraum.map(z => ({
        label: z.title,
        name: z.name
    }))

    const beschaftigungOptions = formValues.beschaftigung.map(b => ({
        label: b.title,
        name: b.name
    }))

    const bewerbungOptions = formValues.bewerbung.map(b => ({
        label: b.title,
        name: b.name,
        textField: b.name === 'b_online' && {
            name: 'b_online_link',
            label: 'Link zum Onlinebewerbungsformular (z.B. https://mywebsite.at/bewerbungsformular.php)'
        }
    }))

    const radioOptions = [
        {label: "Ja", value: 1, selected: data.wohnmoeglichkeit === 1},
        {label: "Nein", value: 0, selected: data.wohnmoeglichkeit === 0}
    ];

    const bruttoNettoOptions = [
        {label: "Netto", value: 1, selected: data.brutto_netto === 1},
        {label: "Brutto", value: 0, selected: data.brutto_netto === 0}
    ];

    const zahlungOptions: LabelValueObjDropdown[] = [
        {value: 'stuendlich', label: 'Stündlich'},
        {value: 'monatlich', label: 'Monatlich'},
        {value: 'jaehrlich', label: 'Jährlich'},
        {value: 'projekteinsatz', label: 'Je Projekteinsatz'}
    ]


    return (
        <>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <h2 className={HelperStyles.textLeft}>Anforderungen</h2>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <MultiCheckbox
                        title="Einsatzbereich"
                        onInputChange={handleChange(setData)}
                        options={einsatzbereichOptions}
                        data={data}
                        required={true}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.job_title}
                        type="text"
                        label="Job Titel"
                        name="job_title"
                        required={true}
                    />

                    {/*job_title*/}
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <TextArea
                        onInputChange={handleChange(setData)}
                        value={data.taetigkeit}
                        label="Genaue Beschreibung der Tätigkeit"
                        name="taetigkeit"
                        required={false}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w50}>
                    <TextArea
                        onInputChange={handleChange(setData)}
                        value={data.vorkenntnisse}
                        label="Vorkenntnisse"
                        name="vorkenntnisse"
                        required={false}
                    />
                </div>

                <div className={HelperStyles.w50}>
                    <TextArea
                        onInputChange={handleChange(setData)}
                        value={data.bevausbildung}
                        label="Bevorzugte Ausbildung"
                        name="bevausbildung"
                        required={false}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <h2 className={HelperStyles.textLeft}>Logistisch</h2>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.dauer}
                        type="text"
                        label="Dauer des Einsatzes (von - bis)"
                        name="dauer"
                        required={true}
                    />

                    {/**/}
                </div>
            </div>

            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <MultiCheckbox
                        title="Zeitraum"
                        canSelectAll
                        onInputChange={handleChange(setData)}
                        options={zeitraumOptions}
                        data={data}
                        required={true}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.gehalt}
                        type="number"
                        label="Entlohnung Betrag EUR"
                        name="gehalt"
                        errorMessage="Bitte geben Sie eine Zahl ein"
                        required={true}
                    />
                </div>
                <div className={HelperStyles.w50}>
                    <InlineRadioButtons
                        onChange={handleChange(setData)}
                        options={bruttoNettoOptions}
                        name="brutto_netto"
                        title="Netto/Brutto"
                        required={true}
                        errorMessage="Bitte wähle eine Option"
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w50}>
                    <CustomDropdown
                        onChange={handleChange(setData)}
                        label="Stündlich / Monatlich / Jährlich / Je Projekteinsatz"
                        value={data.zahlung_select}
                        name="zahlung_select"
                        options={zahlungOptions}
                        required={true}
                        className={HelperStyles.labelHeight50px}
                    />
                </div>

                <div className={HelperStyles.w50}>
                    <TextArea
                        onInputChange={handleChange(setData)}
                        value={data.entlohnung_anmerkung}
                        label="Anmerkung zur Entlohnung (z.B. Trinkgeld, Aufzahlung bei Qualifikation, Sondervereinbarungen,...)"
                        name="entlohnung_anmerkung"
                        required={false}
                        className={HelperStyles.labelHeight50px}
                    />
                </div>
            </div>

            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w50}>
                    <TextArea
                        onInputChange={handleChange(setData)}
                        value={data.arbeitszeit}
                        label="Arbeitszeit / Wochenstunden (z.B. 20h / Woche, Vollzeit, Samstags/Sonntags)"
                        name="arbeitszeit"
                        required={false}
                        className={HelperStyles.labelHeight50px}
                    />
                </div>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.ablaufdatum}
                        type="date"
                        label="Ablaufdatum des Inserates"
                        name="gueltig_bis"
                        required={true}
                        className={HelperStyles.labelHeight50px}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w50}>
                    <AgeComponent
                        onInputChange={handleChange(setData)}
                        value={data.mindestalter}
                        type="text"
                        label="Mindestalter (Jahre)"
                        name="mindestalter"
                        required={true}
                    />
                </div>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.gesamtanz}
                        type="text"
                        label="Anzahl der einzustellenden Jugendlichen (gesamt)"
                        name="gesamtanz"
                        required={true}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <h2 className={HelperStyles.textLeft}>Sonstiges</h2>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <MultiCheckbox
                        title="Art der Beschäftigung"
                        onInputChange={handleChange(setData)}
                        options={beschaftigungOptions}
                        data={data}
                        required={true}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <RadioButtons
                        onChange={handleChange(setData)}
                        options={radioOptions}
                        name="wohnmoeglichkeit"
                        title="Wohnmöglichkeit vorhanden"
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <MultiCheckbox
                        title="Bewerbung"
                        onInputChange={handleChange(setData)}
                        options={bewerbungOptions}
                        data={data}
                    />
                </div>
            </div>
        </>
    );
};


export default ThirdStep;
