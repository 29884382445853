import React, {ChangeEvent, useEffect, useState} from "react";
import Loader from 'react-loader';

import {useDispatch, useSelector} from "react-redux";

import JobSearchForm from "./SearchJobs/JobSearchForm";
import {submitJobSearch} from '../api/SearchJobForm'
import JobList from "./SearchJobs/JobList";
import JobDetail from "./SearchJobs/JobDetail";
import Sorting from "./SearchJobs/Sorting";
import SiteStyle from "../styles/site.module.scss";
import JPStyles from '../styles/jobpreview.module.scss';
import InputStyles from '../styles/input.module.scss';

import {getJobDetail, getOverview, setBaseUrl} from "../redux/actions/SearchJob.actions";

const SearchJobs = () => {

    const jobId = useSelector((state: any) => state.SearchJob.jobId);
    const jobDetailActive = useSelector((state: any) => state.SearchJob.jobDetailActive)
    const [searchData, setSearchData] = useState();
    const [jobs, setJobs] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState();
    const [sending, setSending] = useState(false);
    const dispatch = useDispatch();

    const setComponentBasedOnUrl = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get('jid')) {
            dispatch(getJobDetail(urlParams.get('jid')))
        } else {
            dispatch(getOverview())
        }
    }

    useEffect(() => {
        dispatch(setBaseUrl(window.location.href.split('?')[0]));
        setComponentBasedOnUrl()
        setSearchData({
            order_by: "created_at",
            sort: "DESC"
        });
        submitJobSearch({
            order_by: "created_at",
            sort: "DESC"
        }).then(response => {
            setPages(response.last_page);
            setJobs(response.data);
        })
        window.addEventListener('popstate', setComponentBasedOnUrl)

        return () => {
            window.removeEventListener('popstate', setComponentBasedOnUrl)
        }

    }, []);


    const getData = (data: {[key: string]: any}) => {
        setSending(true)
        const months = ['m1', 'm2', 'm3', 'm4', 'm5', 'm6', 'm7', 'm8', 'm9', 'm10', 'm11', 'm12'];
        const art = ['ferialjob', 'nebenjob', 'pflichtpraktikum', 'samstagsaushilfe'];
        const requestData = {searchData};
        requestData['zeitraum'] = months.filter(month => data[month]);
        requestData['bezirk_id'] = data.bezirk;
        requestData['alter'] = data.age;
        requestData['taetigkeit'] = data.taetigkeiten;
        requestData['art'] = art.filter(art => data[art]);
        requestData['page'] = 1;
        setPage(1);
        setSearchData(requestData);
        submitJobSearch(requestData).then(response => {
            setSending(false)
            setPages(response.last_page);
            setJobs(response.data);
        })
    };

    const getOrdering = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, orderBy: string) => {
        e.preventDefault();
        if (orderBy === 'created_at') {
            requestJobs('order_by', orderBy, 'DESC')
        } else {
            requestJobs('order_by', orderBy, 'ASC')
        }
    };

    const requestJobs = (key: string | number, value: any, sort = 'DESC') => {
        setSending(true)
        const requestData = {...searchData, [key]: value, sort: sort}
        setSearchData(requestData);
        submitJobSearch(requestData).then(response => {
            setSending(false)
            setPages(response.last_page)
            setJobs(response.data);
        }).catch(e => {
            console.error(e)

        })
    };

    const getItemsPerPage = (e: ChangeEvent<HTMLSelectElement>) => {
        const perPage = e.target.value;
        requestJobs('limit', perPage)
    };

    const setCurrentPage = (dir: string) => {
        let currentPage = page;
        switch (dir) {
            case 'PREV':
                if (currentPage > 1) {
                    currentPage--;
                    setPage(currentPage);
                    requestJobs('page', currentPage);
                }
                break;

            case 'NEXT':
                if (currentPage < pages) {
                    currentPage++;
                    setPage(currentPage);
                    requestJobs('page', currentPage);
                }
                break;

            default:
                break;
        }
    };


    return (
        <>
            <div className={SiteStyle.formContainer}>
                <JobSearchForm getData={getData}/>
                {jobDetailActive ? '' : <div className={JPStyles.sortingContainer}>
                    <div className={JPStyles.pages}>Seite {page} von {pages}</div>
                    <Sorting getOrdering={getOrdering} getItemsPerPage={getItemsPerPage}/>
                </div>}
                {jobDetailActive ? <JobDetail id={jobId}/> :
                    <>
                        <JobList jobs={jobs}/>
                        <button className={`${InputStyles.btnSorting} ${page > 1 ? InputStyles.active : ''}`}
                                onClick={() => setCurrentPage('PREV')}>prev
                        </button>
                        <button className={`${InputStyles.btnSorting} ${page < pages ? InputStyles.active : ''}`}
                                onClick={() => setCurrentPage('NEXT')}>next
                        </button>
                    </>}
                {sending ? <Loader loaded={false}/> : ''}
            </div>
        </>
    )
};

export default SearchJobs