import React, {FunctionComponent, useEffect, useState} from "react";
import CustomDropdown, {LabelValueObjDropdown} from "../../components/CustomDropdown";
import {getGemeinden} from '../../api/GemeindeErstberatungApi'


import HelperStyles from "../../styles/helper.module.scss";
import {GemeindeList, GemeindeZukunftsWerkstattStep} from "./GemeindeZukunftsWerkstattData";
import {handleChange} from "../../helpers";
import {pipe} from "fp-ts/es6/pipeable";
import {failure, initial, map, pending, RemoteData, success} from "@devexperts/remote-data-ts/es6";
import {flow} from "fp-ts/es6/function";
import {map as mapE} from "fp-ts/es6/Either";
import {map as mapA} from "fp-ts/es6/Array";
import RenderRemoteEither from "../../components/RenderRemoteEither";
import {Validation} from "io-ts";

const FirstStep: FunctionComponent<GemeindeZukunftsWerkstattStep> = ({data, setData, formValues}) => {

    const [gemeindeOptions, setGemeindeOptions] = useState<RemoteData<string, Validation<GemeindeList>>>(initial);
    const bezirkOptions = formValues.bezirk_list.map(b => ({
        label: b.bezeichnung,
        value: b.id
    }))


    useEffect(() => {
        if (data.bezirk_id) {
            setGemeindeOptions(pending)
            getGemeinden(data.bezirk_id).then(response => {
                    setGemeindeOptions(success(response))
                }
            ).catch(e => setGemeindeOptions(failure(e)))
        }
    }, [data])


    return (
        <>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <h2 className={HelperStyles.textLeft}>Gemeinde und Bezirk</h2>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <CustomDropdown required={true} value={data.bezirk_id} name={'bezirk_id'} options={bezirkOptions}
                                    label={'Bezirk'} onChange={handleChange(setData)}/>
                </div>
            </div>
            {pipe(
                gemeindeOptions,
                map(flow(mapE(flow(d => d.gemeinde_list, mapA((g): LabelValueObjDropdown => ({
                    label: g.gemeinde_name,
                    value: g.id
                })))))),
                formValues =>
                    <RenderRemoteEither renderStep={(_: any, filterData: LabelValueObjDropdown[]) => <>
                        <div className={HelperStyles.flex}>
                            <div className={HelperStyles.wfull}>
                                <CustomDropdown required={true} value={data.gemeinde_id} name={'gemeinde_id'}
                                                options={filterData}
                                                label={'Gemeinde'}/>
                            </div>
                        </div>
                    </>} step={0} formValues={formValues}/>
            )}
        </>
    );
};

export default FirstStep;
