type EnterJobObject = {
  SUBMIT: string;
  NEXT: string;
  PREV: string;
  SET_DATA: string;
  SET_STEP: string;
  BS_OUT: string;
};

const EnterJob: EnterJobObject = {
  SUBMIT: "SUBMIT",
  NEXT: "NEXT",
  PREV: "PREV",
  SET_DATA: "SET_DATA",
  SET_STEP: "SET_STEP",
  BS_OUT: "BS_OUT"
};

export default EnterJob;
