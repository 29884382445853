import {CSSProperties} from "react";

export const InfoButtonYellowBig: CSSProperties = {
    height: '25px',
    width: '25px',
    fontSize: '11px',
    backgroundColor: '#fd3',
    marginLeft: '10px'
}

export const InfoButtonGreySmall: CSSProperties = {
    height: '20px',
    width: '20px',
    fontSize: '8px',
    backgroundColor: '#d3d3d3',
    marginLeft: '10px'
}