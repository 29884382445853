import React from "react";

import {useDispatch} from "react-redux";
import {getJobDetail} from "../../redux/actions/SearchJob.actions";

import HelperStyles from '../../styles/helper.module.scss';
import JPStyles from '../../styles/jobpreview.module.scss';


const JobPreview = ({cName, ort, taetigkeit, id, date}) => {

    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(getJobDetail(id))
    };


    return (
        <div className={JPStyles.container} onClick={handleClick}>
            <div className={JPStyles.card} onClick={handleClick}>

                <div className={`${JPStyles.ortDate}`}><h3 className={JPStyles.headingOrt}>{ort}</h3>  <h3 className={JPStyles.headingOrt}>{date}</h3></div>
                <h3 className={JPStyles.heading}>{cName}</h3>

            <div className={`${HelperStyles.flex} ${HelperStyles.textLeft}`}>
                <div className={`${HelperStyles.wfull}`}><p dangerouslySetInnerHTML={{__html: taetigkeit}}></p></div>
            </div>
            </div>
        </div>
    )
};

export default JobPreview;