import React, {FunctionComponent, useEffect, useState} from "react";
import Loader from 'react-loader';

import {FormContext, useForm} from "react-hook-form";
import {useAlert} from 'react-alert';
import {Steps} from "./GemeindeErstberatungFormSteps/Steps";
import {connect} from "react-redux";
import {back, next, setData, setFormValues} from "../redux/actions/GemeindeErstberatung.actions";

import SiteStyle from "../styles/site.module.scss";
import InputStyle from "../styles/input.module.scss";
import ProgressStyle from "../styles/progressbar.module.scss";

import {fetchData, submitForm} from '../api/GemeindeErstberatungApi';

import ProgressBarGemeindeErstberatung from "./GemeindeErstberatungFormSteps/ProgressBarGemeindeErstberatung";

import FirstStep from "./GemeindeErstberatungFormSteps/FirstStep";
import SecondStep from "./GemeindeErstberatungFormSteps/SecondStep";
import Finished from "../components/Finished";
import ThirdStep from "./GemeindeErstberatungFormSteps/ThirdStep";
import {EMAIL_LOOKUP, ERROR_TEXT} from "../Text/text";
import {useSubmit} from "../helpers/UseSubmit";
import useButtons from "../helpers/UseButtons";
import RenderRemoteEither from "../components/RenderRemoteEither";
import {RemoteData} from "@devexperts/remote-data-ts";
import {Validation} from "io-ts";
import {BezirkList} from "./GemeindeErstberatungFormSteps/GemeindeErstberatungData";
import {failure, initial, success} from "@devexperts/remote-data-ts/es6";

const GemeindeZukunftsWerkstattFormPage: FunctionComponent<{
    step: number;
    data: any;
    next: any;
    back: any;
    setData: any;
    direction: string;
}> = ({step, next, back, setData, data}) => {
    const methods = useForm();


    const alert = useAlert();
    const {sending, onSubmit} = useSubmit({
        showErrorsAction: alert.show,
        formFinished: next,
        submitAction: submitForm,
        data: () => {
            const formData = {...data, type: 'Erstberatung'};
            delete formData['bezirk_id'];
            return formData
        },
        errorText: ERROR_TEXT
    })

    const validatedNext = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const fields: any = Steps;
        const isValid = await methods.triggerValidation(fields[`${step}`]);

        if (isValid) {
            setData(methods.getValues());
            next();
        }
    };

    const validateBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        if (step > 1) {
            back();
        }
    };

    const {submitButton, nextButton, prevButton} = useButtons(step, Object.keys(Steps).length)

    const [formValues, setFormValues] = useState<RemoteData<string, Validation<BezirkList>>>(initial)
    useEffect(() => {
        fetchData().then(data => {
            setFormValues(success(data));
        }).catch(e => setFormValues(failure(e)))
    }, []);

    const renderStep = (step: number, formValues: BezirkList) => {
        switch (step) {
            case 1:
                return <FirstStep formValues={formValues} setData={setData} data={data} {...methods} />;

            case 2:
                return <SecondStep formValues={formValues} setData={setData} data={data} {...methods} />;

            case 3:
                return <ThirdStep formValues={formValues} setData={setData} data={data} {...methods} />

            case 4:
                return <Finished text={EMAIL_LOOKUP}/>;

            default:
                return <></>
        }
    };


    return (
        <div className={SiteStyle.formContainer}>

            <div className={ProgressStyle.wrapper}>
                <ProgressBarGemeindeErstberatung/>
            </div>
            <FormContext {...methods}>
                <form
                    className={InputStyle.form}
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <RenderRemoteEither showLoadingOnInitial={true} renderStep={renderStep} step={step} formValues={formValues}/>
                    <div className={InputStyle.btnWrapper}>
                        <button
                            className={InputStyle.btn}
                            disabled={!prevButton}
                            onClick={(e) => validateBack(e)}
                        >
                            zurück
                        </button>
                        {submitButton ? (
                            <button
                                className={InputStyle.btn}
                                disabled={!submitButton}
                                type="submit"

                            >Senden {sending ? <Loader loaded={false}/> : ''}</button>
                        ) : (
                            <button
                                className={InputStyle.btn}
                                disabled={!nextButton}
                                onClick={(e) => validatedNext(e)}
                            >
                                Nächster Schritt
                            </button>
                        )}
                    </div>
                </form>
            </FormContext>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        step: state.GemeindeErstberatungForm.step,
        data: state.GemeindeErstberatungForm.data,
        direction: state.GemeindeErstberatungForm.direction
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        next: () => dispatch(next()),
        back: () => dispatch(back()),
        setData: (data: Object) => dispatch(setData(data)),
        setFormValues: (data: Object) => dispatch(setFormValues(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GemeindeZukunftsWerkstattFormPage);
