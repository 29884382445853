import React, {FunctionComponent, useEffect, useState} from "react";
import Loader from 'react-loader';
import {FormContext, useForm} from "react-hook-form";
import Finished from "../components/Finished"
import {useAlert} from 'react-alert';
import {Steps} from "./EnterJobFormSteps/Steps";
import {connect} from "react-redux";
import {back, next, setData,} from "../redux/actions/EnterJob.actions";

import SiteStyle from "../styles/site.module.scss";
import InputStyle from "../styles/input.module.scss";
import ProgressStyle from "../styles/progressbar.module.scss";

import {fetchEnterJobFormData, submitEnterJobForm} from '../api/EnterJobForm';

import ProgressBarEnterJob from "./EnterJobFormSteps/ProgressBarEnterJob";

import FirstStep from "./EnterJobFormSteps/FirstStep";
import SecondStep from "./EnterJobFormSteps/SecondStep";
import ThirdStep from "./EnterJobFormSteps/ThirdStep";
import FourthStep from "./EnterJobFormSteps/FourthStep";
import {EMAIL_LOOKUP, ERROR_TEXT} from "../Text/text";
import {useSubmit} from "../helpers/UseSubmit";
import useButtons from "../helpers/UseButtons";
import {failure, initial, pending, RemoteData, success} from "@devexperts/remote-data-ts/es6";
import {Either} from "fp-ts/es6/Either";
import {Errors} from "io-ts";
import {EnterJobData} from "./EnterJobFormSteps/EnterJobData";
import RenderRemoteEither from "../components/RenderRemoteEither";

const EnterJobForm: FunctionComponent<{
    step: number;
    data: any;
    next: any;
    back: any;
    setData: any;
    bsOut: boolean;
}> = ({step, next, back, setData, data, bsOut}) => {

    const alert = useAlert();
    const {sending, onSubmit} = useSubmit({
        showErrorsAction: alert.show,
        formFinished: next,
        submitAction: submitEnterJobForm,
        data: () => data,
        errorText: ERROR_TEXT
    })

    const methods = useForm();

    const validatedNext = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const fields: { [key: string]: string[] } = Steps;

        let isValid = await methods.triggerValidation(fields[`${step}`]);
        let validatedData = methods.getValues();
        if (step === 1) {
            let adress = data.adresse;
            if (!IsJsonString(adress)) {
                isValid = false;
            }

            validatedData['adresse'] = adress;
        }

        if (step === 2) {
            let bs_adress = data.bs_adresse;
            if (!IsJsonString(bs_adress)) {
                isValid = false;
            }
            validatedData['bs_adresse'] = bs_adress;
        }


        if (isValid) {
            setData(validatedData);
            next();
        } else if (step === 2 && !bsOut) {
            setData(methods.getValues());
            next();
        }
    };

    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const validateBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (step > 1) {
            back();
        }

    };

    const {submitButton, nextButton, prevButton} = useButtons(step, Object.keys(Steps).length)
    const [formValues, setFormValues] = useState<RemoteData<unknown, Either<Errors, EnterJobData>>>(initial)
    useEffect(() => {
        setFormValues(pending)
        fetchEnterJobFormData().then(data => {
            setFormValues(success(data))
        }).catch(e => {
            setFormValues(failure(e))
        })
    }, [])


    const renderStep = (step: number, formValues: EnterJobData): JSX.Element => {
        switch (step) {
            case 1:
                return <FirstStep setData={setData} formValues={formValues} data={data} {...methods} />;

            case 2:
                return <SecondStep setData={setData} formValues={formValues} data={data} {...methods} />;

            case 3:
                return <ThirdStep setData={setData} formValues={formValues} data={data} {...methods} />;

            case 4:
                return <FourthStep setData={setData} formValues={formValues} data={data} {...methods} />;

            case 5:
                return <Finished text={EMAIL_LOOKUP}/>;
            default:
                return <></>
        }
    };

    return (
        <div className={SiteStyle.formContainer}>
            <>
                <div className={ProgressStyle.wrapper}>
                    <ProgressBarEnterJob/>
                </div>
                <FormContext {...methods}>
                    <form
                        className={InputStyle.form}
                        onSubmit={methods.handleSubmit(onSubmit)}
                    >

                        <RenderRemoteEither showLoadingOnInitial={true} step={step} formValues={formValues}
                                            renderStep={renderStep}/>
                        <div className={InputStyle.btnWrapper}>
                            <button
                                className={InputStyle.btn}
                                disabled={!prevButton}
                                onClick={(e) => validateBack(e)}
                            >
                                zurück
                            </button>
                            {submitButton ? (
                                <button
                                    className={InputStyle.btn}
                                    disabled={!submitButton}
                                    type="submit"

                                >Senden {sending ? <Loader loaded={false}/> : ''}</button>
                            ) : (
                                <button
                                    className={InputStyle.btn}
                                    disabled={!nextButton}
                                    onClick={(e) => validatedNext(e)}
                                >
                                    Nächster Schritt
                                </button>
                            )}
                        </div>
                    </form>
                </FormContext>
            </>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        step: state.EnterJobForm.step,
        data: state.EnterJobForm.data,
        bsOut: state.EnterJobForm.bsOut,
        direction: state.EnterJobForm.direction
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        next: () => dispatch(next()),
        back: () => dispatch(back()),
        setData: (data: Object) => dispatch(setData(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterJobForm);

