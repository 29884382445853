import React, {FunctionComponent} from "react";
import {useFormContext} from "react-hook-form";

import Input from "../styles/input.module.scss";
import HelperStyles from "../styles/helper.module.scss";

const MultiCheckboxForJobSearch: FunctionComponent<{
    options: {value: string | number, label: string |number}[];
    title: string;
    data: any;
    onInputChange: Function;
}> = ({options, data, onInputChange, title}) => {
    const {register} = useFormContext();
    const dataOptions = options ? [...options] : null;


    return (
        <>
            <div className={HelperStyles.flex}>
                <span>{title}</span>
            </div>
            <div className={Input.multiCol}>
                {dataOptions !== null
                    ? dataOptions.map(option => {
                        return (
                            <label key={option.value} className={Input.textLabel}>
                                <input
                                    defaultChecked={data[`${option.value}`]}
                                    type="checkbox"
                                    name={`${option.value}`}
                                    id={`${option.value}`}
                                    className={Input.checkbox}
                                    ref={register}
                                    onChange={onInputChange()}
                                />
                                <span className={Input.checkboxLabel}>
                                {option.label}
                                </span>
                            </label>

                        );
                    })
                    : ""}
            </div>

        </>
    );
};

export default MultiCheckboxForJobSearch;
