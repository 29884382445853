import {apiToken, baseUrl} from "./index";
import {Either} from "fp-ts/es6/Either";
import {Errors} from "io-ts";
import {GiveTutoringData} from "../pages/GiveTutoringFormSteps/GiveTutoringData";
import {SearchTutoringData} from "../pages/SearchTutoringFormSteps/SearchTutoringData";

const url = baseUrl + "/get-lernboerse-options";
const submitUrl = baseUrl + "/store-lernboerse";


export const fetchGiveTutoringData = async (): Promise<Either<Errors, GiveTutoringData>> => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization' : 'Bearer '+apiToken
        },
    })
    const data = await response.json();

    return GiveTutoringData.decode(data)
};

export const fetchSearchTutoringData = async (): Promise<Either<Errors, SearchTutoringData>> => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization' : 'Bearer '+apiToken
        },
    });
    const data = await response.json();

    return SearchTutoringData.decode(data)
};

export const submitForm = async (data: {[key: string]: any}) => {

    const response = await fetch(submitUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization' : 'Bearer '+apiToken
        },
        body: JSON.stringify(data)
    });

    return await response.json();
};