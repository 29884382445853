import React, {FunctionComponent, useEffect} from "react";
//@ts-ignore
import MultiSelect from "@khanacademy/react-multi-select";
import '../styles/multiselect.scss';

import HelperStyles from '../styles/helper.module.scss'
import {Controller, ErrorMessage, useFormContext} from "react-hook-form";
import Input from "../styles/input.module.scss";

const MultiSelectDropdown: FunctionComponent<{ options: Object, selectedValues: any, onSelect: Function, label: string, name: string, required?: boolean }> =
    ({options, onSelect, selectedValues, label, name, required}) => {

        const {control, errors, register, setValue} = useFormContext();

        const select = (selected: string | number) => {
            setValue(name, selectedValues);
            return onSelect(selected)
        }

        setValue(name, selectedValues);

        const atLeastOneChecked = (value) => {
            return value.length > 0 || !required || 'Bitte wähle mindestens eine Option aus'
        };

        useEffect(() => {
            register({name: name})
        }, [])

        return <>
            <div className={HelperStyles.textLeft}>{label}{label && required ? '*' : ''}</div>
            <Controller control={control} name={name} rules={{validate: (value) => atLeastOneChecked(value)}} as={
                <MultiSelect overrideStrings={{
                    selectSomeItems: "Wähle aus...",
                    allItemsAreSelected: "Alles ausgewählt",
                    selectAll: "Alle auswählen",
                    search: "Suche",
                }} name={name} options={options}
                             selected={selectedValues} onSelectedChanged={select}/>

            }/>

            <ErrorMessage
                errors={errors}
                name={name}
                as={<span className={Input.errorMessage}></span>}
            />

        </>
    };

export default MultiSelectDropdown