import React from "react";

import InputStyles from '../../styles/input.module.scss'
import JPStyles from '../../styles/jobpreview.module.scss'
import {setOrdering} from "../../redux/actions/SearchJob.actions";

import {useDispatch, useSelector} from 'react-redux'

type Sorting = {
    getOrdering: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, order: string) => void;
    getItemsPerPage: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Sorting: React.FC<Sorting> = ({getOrdering, getItemsPerPage}) => {

    const dispatch = useDispatch()
    const ordering = useSelector((state: any) => state.SearchJob.ordering);

    const setUserOrdering = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, order: string) => {
        getOrdering(e, order)
        dispatch(setOrdering(order))
    };

    return(
        <div className={JPStyles.sortingContainer}>
            <button className={`${InputStyles.btnSorting} ${ordering === 'ort' ? InputStyles.active : ''}`} onClick={(e) => setUserOrdering(e, 'ort')}>Ort</button>
            <button className={`${InputStyles.btnSorting} ${ordering === 'firma' ? InputStyles.active : ''}`} onClick={(e) => setUserOrdering(e, 'firma')}>Firma</button>
            <button className={`${InputStyles.btnSorting} ${ordering === 'created_at' ? InputStyles.active : ''}`} onClick={(e) => setUserOrdering(e, 'created_at')}>die Aktuellsten</button>
            <select className={InputStyles.dropdownPerPage} onChange={(e) => getItemsPerPage(e)} name="limitPerPage" id="limitPerPage">
                <option value="10">10</option>
                <option selected={true} value="25">25</option>
                <option value="50">50</option>
            </select>
        </div>
    )
};

export default Sorting;