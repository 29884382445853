import EnterJob from "../constants/EnterJob.constants";

export const next = () => ({
  type: EnterJob.NEXT
});

export const back = () => ({
  type: EnterJob.PREV
});

export const setData = (data: any) => ({
  type: EnterJob.SET_DATA,
  payload: data
});

export const setStep = (step: number) => ({
  type: EnterJob.SET_STEP,
  payload: step
});

export const setBsOut = () => ({
  type: EnterJob.BS_OUT
});
