import SearchJobs from "../constants/SearchJob.constants";

const initialState = {
    jobId: null,
    jobDetailActive: false,
    baseUrl: window.location.href.split('?')[0],
    ordering: 'created_at',
    jobsearchFormOpen: true
};

const SearchJob = (state = initialState, action) => {
    switch (action.type) {
        case SearchJobs.JOB_DETAIL:
            const queryParam = new URLSearchParams({jid: action.payload});
            const newUrl = state.baseUrl + '?' + queryParam.toString();
            if (action.payload !== state.jobId) {
                window.history.pushState({}, '', newUrl);
            }
            return {...state, jobId: action.payload, jobDetailActive: true, jobsearchFormOpen: false};

        case SearchJobs.GET_OVERVIEW:
            window.history.pushState({}, '', state.baseUrl)
            return {...state, jobId: null, jobDetailActive: false, jobsearchFormOpen: true};

        case SearchJobs.SET_ORDERING:
            return {...state, ordering: action.payload};

        case SearchJobs.TOGGLE_JOBSEARCH_FORM:
            return {...state, jobsearchFormOpen: !state.jobsearchFormOpen}

        case SearchJobs.SET_BASE_URL:
            return {...state, baseUrl: action.payload}

        default:
            return state;
    }
};

export default SearchJob;