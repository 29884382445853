import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import {AlertProviderProps, positions, Provider as AlertProvider, transitions} from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

import {Provider} from "react-redux";
import store from "./redux/store/index";

const options: AlertProviderProps = {
    position: positions.BOTTOM_CENTER,
    type: 'error',
    timeout: 1000,
    offset: '30px',
    transition: transitions.SCALE,
    template: AlertTemplate
}


ReactDOM.render(
    <Provider store={store}>
        <AlertProvider {...options}>
            <App/>
        </AlertProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
