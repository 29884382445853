import GiveTutoring from "../constants/GiveTutoring.constants";
import {Steps} from "../../pages/GiveTutoringFormSteps/Steps";

type actionObject = {
    type: string;
    payload: any;
};

const initialState = {
    step: 1,
    lastActivatedStep: 1,
    bsOut: false,
    direction: "NEXT",
    data: {
        anrede: '',
        vorname: '',
        nachname: '',
        strasse: '',
        plz: '',
        ort: '',
        bezirk_id: '',
        bevorzugter_bezirk: [],
        nachhilfeort: 0,
        feriennh: 0
    },
    formValues: {},
};

const GiveTutoringForm = (state = initialState, action: actionObject) => {
    switch (action.type) {
        case GiveTutoring.NEXT:
            if (state.lastActivatedStep === state.step) {
                return {
                    ...state,
                    step: state.step + 1,
                    lastActivatedStep: state.step + 1,
                    direction: "NEXT"
                };
            }
            return { ...state, step: state.step + 1, direction: "NEXT" };

        case GiveTutoring.PREV:
            return { ...state, step: state.step - 1, direction: "PREV" };

        case GiveTutoring.SET_DATA:
            const payload = { ...action.payload };
            return { ...state, data: { ...state.data, ...payload } };

        case GiveTutoring.SET_STEP:
            if (action.payload <= state.lastActivatedStep && state.lastActivatedStep !== Object.keys(Steps).length) {
                return { ...state, step: action.payload };
            }
            return state;

        case GiveTutoring.SET_FORM_VALUES:
            return { ...state, formValues: action.payload };

        default:
            return state
    }
};

export default GiveTutoringForm;