type GiveTutoringObject = {
    SUBMIT: string;
    NEXT: string;
    PREV: string;
    SET_DATA: string;
    SET_STEP: string;
    SET_FORM_VALUES: string
};

const GiveTutoring: GiveTutoringObject = {
    SUBMIT: "SUBMIT",
    NEXT: "NEXT",
    PREV: "PREV",
    SET_DATA: "SET_DATA",
    SET_STEP: "SET_STEP",
    SET_FORM_VALUES: 'SET_FORM_VALUES'
};

export default GiveTutoring;
