export const Steps = {
    1: ['anrede', 'vorname', 'nachname', 'strasse', 'plz', 'ort', 'bezirk_id', 'bevorzugter_bezirk', 'gebdatum', 'telefon', 'email', 'beruf', 'bildungsabschluss'],
    2: ['datenschutz', 'erfahrungen'],
    3: []
};

export const StepsHeading = {
    1: 'Kontakt',
    2: 'Sonstiges',
    3: 'Abschluss'
}