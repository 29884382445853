import React, {FunctionComponent} from "react";
import NormalCheckBox from '../../components/NormalCheckBox';

import HelperStyles from "../../styles/helper.module.scss";
import {handleChange} from "../../helpers";

import UserAddNewTutoring from "../../components/UserAddNewTutoring";
import {SearchTutoringStep} from "./SearchTutoringData";

const SecondStep: FunctionComponent<SearchTutoringStep> = ({data, setData, formValues}) => {

    const faecher = formValues.faecher.map((data) => ({
        label: data.fach_name,
        value: data.id,
    }));

    const stufen = formValues.stufe.map((data) => ({
            label: data.stufe_name,
            value: data.id,
        }));

    const setUserChoices = (choices: unknown, name: string |number) => {
        setData({[`${name}`]: choices})
    };

    return (
        <>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <UserAddNewTutoring data={data.faecher_table}
                                        handleChange={(choices: unknown, name: string | number) => setUserChoices(choices, name)}
                                        name={'faecher_table'}
                                        faecher={faecher} stufen={stufen}/>
                </div>
            </div>

            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <NormalCheckBox
                        onInputChange={handleChange(setData)}
                        value={parseInt(data.datenschutz) ? 0 : 1}
                        checked={!!parseInt(data.datenschutz)}
                        title=""
                        required={true}
                        name="datenschutz"
                        label={
                            "Mit der Teilnahme an der Lernbörse erkläre ich mich mit den <a target='blank' rel='nofollow noopener' href='https://www.jugendservice.at/datenschutz/'>Datenschutzbestimmungen</a> einverstanden."
                        }
                        errorMessage={"Bitte akzeptieren Sie die Datenschutzerklärung"}
                    /></div>
            </div>
        </>
    );
};

export default SecondStep;
