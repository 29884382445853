import React, {CSSProperties} from "react";
import InputStyles from "../styles/input.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons/faInfo";
import ReactTooltip from "react-tooltip";

const InfoSign: React.FC<{ text: string; style: CSSProperties }> = ({text, style}) => {

    return <>
        <div style={style} className={InputStyles.infoButtonMinStyle} data-for={text.replace(' ', '_')} data-tip={text}><FontAwesomeIcon icon={faInfo}/></div>
        <ReactTooltip id={text.replace(' ', '_')} effect={'solid'}/>
    </>
}

export default InfoSign