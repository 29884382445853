export const Steps = {
    1: [],
    2: ['anrede', 'title', 'vorname', 'nachname', 'mail', 'telefon', 'buergermeisterin_name', 'name_jugendausschussobmann', 'name_gemeindejugendreferent'],
    3: ['datenschutzbestimmungen'],
    4: []
};

export const StepsHeading = {
    1: 'Gemeinde Auswahl',
    2: 'Kontakt',
    3: 'Sonstiges',
    4: 'Abschluss'
};