import SearchJobs from "../constants/SearchJob.constants";

export const getJobDetail = (id) => ({
    type: SearchJobs.JOB_DETAIL,
    payload: id
});

export const getOverview = () => ({
    type: SearchJobs.GET_OVERVIEW
});

export const setOrdering = (ordering) => ({
    type: SearchJobs.SET_ORDERING,
    payload: ordering
});

export const toggleJobsearchForm = () => ({
    type: SearchJobs.TOGGLE_JOBSEARCH_FORM,
});

export const setBaseUrl = (url: string) => ({
    type: SearchJobs.SET_BASE_URL,
    payload: url
})