import GemeindeErstberatung from "../constants/GemeindeErstberatung.constants";

export const next = () => ({
    type: GemeindeErstberatung.NEXT
});

export const back = () => ({
    type: GemeindeErstberatung.PREV
});

export const setData = (data: any) => ({
    type: GemeindeErstberatung.SET_DATA,
    payload: data
});

export const setStep = (step: number) => ({
    type: GemeindeErstberatung.SET_STEP,
    payload: step
});

export const setFormValues = (data: any) => ({
    type: GemeindeErstberatung.SET_FORM_VALUES,
    payload: data
})