import React, {useEffect, useState} from "react";
import {FormContext, useForm} from "react-hook-form";
import InputStyle from "../../styles/input.module.scss";
import HelperStyles from "../../styles/helper.module.scss";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

import {useDispatch, useSelector} from "react-redux";
import {getOverview, toggleJobsearchForm} from "../../redux/actions/SearchJob.actions";

import {fetchData} from "../../api/SearchJobForm";
import TextInput from "../../components/TextInput";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import MultiCheckboxForJobSearch from "../../components/MultiCheckboxForJobSearch";

const JobSearchForm = ({getData}) => {

    const dispatch = useDispatch()

    const methods = useForm();

    const onSubmit = (data) => {
        dispatch(getOverview())
        getData(data)
    };

    const [bezirke, setBezirke] = useState([{label: '', value: 0}]);
    const [taetigkeiten, setTaetigkeiten] = useState([{label: '', value: 0}]);
    const [zeitraum, setZeitraum] = useState([{label: '', value: 0}])
    const [jobType, setJobType] = useState([{label: '', value: 0}])

    useEffect(() => {
        fetchData().then(data => {
            setBezirke(data.bezirk.map(bezirk => ({
                value: bezirk.id,
                label: bezirk.bezeichnung
            })));

            setTaetigkeiten(data.einsatzbereich.map(bereich => ({
                value: bereich.name,
                label: bereich.title
            })));

            setZeitraum(data.zeitraum.map(zeitraum => ({
                value: zeitraum.name,
                label: zeitraum.title
            })));

            setJobType(data.beschaftigung.map(beschaftigung => ({
                value: beschaftigung.name,
                label: beschaftigung.title
            })))
        })
    }, [])

    const [teatigkeitenSelected, setTaetigkeitenSelected] = useState([])
    const [zeitraumSelected, setZeitraumSelected] = useState([])
    const [jobTypeSelected, setJobTypeSelected] = useState([])

    const setTaetigkeitValues = (values) => {
        setTaetigkeitenSelected(values)
    };

    const setUserZeitraum = (value) => {
        setZeitraumSelected(value)
    };

    const setUserJobTypeSelected = (value) => {
        setJobTypeSelected(value)
    };

    const formOpen = useSelector((state: any) => state.SearchJob.jobsearchFormOpen);

    return (
        <FormContext {...methods}>
            <div className={InputStyle.formContainer}>
                    <button className={`${InputStyle.toggleButton}`} onClick={() => dispatch(toggleJobsearchForm())}>
                       Suche <FontAwesomeIcon icon={formOpen ? faChevronUp : faChevronDown}/></button>

                <form
                    className={`${InputStyle.form} ${formOpen ? '' : InputStyle.formClosed}`}
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <div className={HelperStyles.flex}>
                        <div className={HelperStyles.w50}>
                            <label htmlFor="bezirk">
                                <div className={HelperStyles.flex}>
                                    <span>Bezirk</span>
                                </div>
                                <select required className={InputStyle.dropDown} ref={methods.register} name='bezirk'
                                        id="bezirk">
                                    <option disabled selected value="">Bitte wähle eine Option</option>
                                    {bezirke.map(bezirk => (
                                        <option value={bezirk.value}>{bezirk.label}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div className={HelperStyles.w50}>
                            <TextInput value={''} name={'age'} label={'Alter'} type={'number'} required={false}
                                       onInputChange={() => true}/>
                        </div>
                    </div>
                    <div className={HelperStyles.flex}>
                        <div className={HelperStyles.w50}>
                            <MultiSelectDropdown required={false} options={taetigkeiten}
                                                 selectedValues={teatigkeitenSelected}
                                                 onSelect={setTaetigkeitValues} label={'Tätigkeiten'}
                                                 name={'taetigkeiten'}/>
                        </div>
                    </div>
                    <div className={HelperStyles.flex}>
                        <div className={HelperStyles.w50}>
                            <MultiCheckboxForJobSearch options={zeitraum} title={'Zeitraum'} data={zeitraumSelected}
                                                       onInputChange={() => setUserZeitraum}/>
                        </div>
                    </div>
                    <div className={HelperStyles.flex}>
                        <div className={HelperStyles.w50}>
                            <MultiCheckboxForJobSearch options={jobType} title={'Beschäftigungsart'} data={jobTypeSelected}
                                                       onInputChange={() => setUserJobTypeSelected}/>
                        </div>
                    </div>
                    <input className={`${InputStyle.btn}`} type="submit" value={'Suchen'}/>
                </form>

            </div>
        </FormContext>
    )
};

export default JobSearchForm