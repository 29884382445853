import React, {FunctionComponent} from "react";
import {ErrorMessage, useFormContext} from "react-hook-form";

import Input from "../styles/input.module.scss";
import HelperStyles from "../styles/helper.module.scss";

type addField = {
    name: string,
    label?: string,
}
export type LabelValueObjMultiCheckbox = {
    label: string;
    name: string;
    textField?: false | addField
};

const MultiCheckbox: FunctionComponent<{
    options: LabelValueObjMultiCheckbox[];
    title: string;
    data: any
    canSelectAll?: boolean
    onInputChange: (data: { value: any, id: string }) => any;
    required?: boolean;
}> = ({options, data, onInputChange, title, required, canSelectAll}) => {
    const {errors, register, watch} = useFormContext();
    const dataOptions = options ? [...options] : null;

    const atLeastOneChecked = () => {
        const checked = options.map((option) => watch(option.name)).includes(true);
        const hasText = options.filter(option => !!data[`${option.name}`] && option.textField)
            .every(withText => withText?.textField ? data[`${withText.textField.name}`] !== "" && data[`${withText.textField.name}`] : true);
        return (hasText && checked) || (hasText && !required) || hasText && 'Bitte kreuze mindestens eine Option an' || 'Bitte fülle das zugehörige Textfeld aus'
    };

    const selectAll = () => {
        if (dataOptions !== null) {
            dataOptions.forEach(option =>
                onInputChange({
                    id: option.name,
                    value: 1
                }))
        }
    }

    return (
        <>
            <div className={HelperStyles.flex}>
                <span>{title}{required && '*'}{canSelectAll && <span> (
                    <a style={{color: "#09c", cursor: "pointer"}}
                       onClick={selectAll}>alle auswählen</a>)</span>}</span>
            </div>
            <div className={Input.multiCol}>
                {dataOptions !== null
                    ? dataOptions.map(option => {
                        return (
                            <label key={option.name} className={Input.textLabel}>
                                <input
                                    defaultChecked={data[`${option.name}`]}
                                    checked={data[`${option.name}`]}
                                    type="checkbox"
                                    name={option.name}
                                    id={option.name}
                                    ref={register({
                                        validate: () => atLeastOneChecked()
                                    })}
                                    onChange={() => onInputChange({
                                        id: option.name,
                                        value: Number(data[`${option.name}`]) === 0 ? 1 : 0
                                    })}
                                />
                                <span className={Input.checkboxLabel}>
                                {option.label}
                                </span>
                                {option.textField ? <><input
                                    onChange={({target: {value}}) => {
                                        option.textField ? onInputChange({id: option.textField?.name, value}) : void (0)
                                    }}
                                    value={data[`${option.textField.name}`]} className={Input.textInput} type="text"
                                    name={option.textField.name} id={option.textField.name}
                                    placeholder={option.textField.label ? option.textField.label : option.label}
                                    disabled={!data[`${option.name}`]}
                                /> </> : ''}
                                <ErrorMessage
                                    name={option.name}
                                    errors={errors}
                                    as={<span className={Input.errorMessage}></span>}
                                />
                            </label>
                        );
                    })
                    : ""}
            </div>

        </>
    );
};

export default MultiCheckbox;
