import React, {FunctionComponent} from "react";
import TextInput from "../../components/TextInput";
import CustomDropdown from '../../components/CustomDropdown'

import HelperStyles from "../../styles/helper.module.scss";
import {handleChange} from "../../helpers";
import {GemeindeZukunftsWerkstattStep} from "./GemeindeZukunftsWerkstattData";

const SecondStep: FunctionComponent<GemeindeZukunftsWerkstattStep> = ({data, setData}) => {


    return (
        <>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <h2 className={HelperStyles.textLeft}>Kontakt Person</h2>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w50}>
                    <CustomDropdown
                        name={'anrede'}
                        value={data.anrede}
                        options={[{label: 'Herr', value: 'herr'}, {label: 'Frau', value: 'frau'}]}
                        label={'Anrede'}
                        required={true}
                    />
                </div>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.title}
                        type="text"
                        label="Titel"
                        name="title"
                        required={false}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.vorname}
                        type="text"
                        label="Vorname"
                        name="vorname"
                        required={true}
                    />
                </div>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.nachname}
                        type="text"
                        label="Nachname"
                        name="nachname"
                        required={true}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.mail}
                        type="email"
                        label="E-Mail"
                        name="mail"
                        required={true}
                    />
                </div>
                <div className={HelperStyles.w50}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.telefon}
                        type="text"
                        label="Telefon"
                        name="telefon"
                        required={true}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.w25}>
                    <h2 className={HelperStyles.textLeft}>Gemeinde Kontakt Personen</h2>
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.buergermeisterin_name}
                        type="text"
                        label="Name von Bürgermeister*in"
                        name="buergermeisterin_name"
                        required={true}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.name_jugendausschussobmann}
                        type="text"
                        label="Jugendausschuss Obmann"
                        name="name_jugendausschussobmann"
                        required={true}
                    />
                </div>
            </div>
            <div className={HelperStyles.flex}>
                <div className={HelperStyles.wfull}>
                    <TextInput
                        onInputChange={handleChange(setData)}
                        value={data.name_gemeindejugendreferent}
                        type="text"
                        label="Gemeindejugendreferent"
                        name="name_gemeindejugendreferent"
                        required={false}
                    />
                </div>
            </div>
        </>
    );
};

export default SecondStep;
