import React from "react";
import JobPreview from "./JobPreview";

import moment from 'moment';
import 'moment/locale/de-at';

import JPStyles from '../../styles/jobpreview.module.scss'

const JobList = ({jobs}) => {

    moment.locale('de-at');

    return (
        <div className={JPStyles.previewContainer}>
            {jobs.map(job => {
                return (
                    <JobPreview key={job.id} date={moment(job.created_at).format('DD. MMMM YYYY')} id={job.id} cName={job.firma} ort={job.ort}
                                taetigkeit={job.taetigkeit}/>
                )
            })}
        </div>
    )
}

export default JobList;