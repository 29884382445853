import {useEffect, useState} from "react";

const useButtons = (step: number, stepsLength: number) => {
    const [submitButton, setSubmitButton] = useState(false);
    const [nextButton, setNextButton] = useState(false);
    const [prevButton, setPrevButton] = useState(false);

    useEffect(() => {

        (step === stepsLength - 1) ? setSubmitButton(true) : setSubmitButton(false);
        (step < stepsLength - 1) ? setNextButton(true) : setNextButton(false);
        (step > 1 && !(step === stepsLength)) ? setPrevButton(true) : setPrevButton(false);

    }, [step]);

    return {submitButton, nextButton, prevButton}
};

export default useButtons;