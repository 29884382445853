import GiveTutoring from "../constants/GiveTutoring.constants";

export const next = () => ({
    type: GiveTutoring.NEXT
});

export const back = () => ({
    type: GiveTutoring.PREV
});

export const setData = (data: any) => ({
    type: GiveTutoring.SET_DATA,
    payload: data
});

export const setStep = (step: number) => ({
    type: GiveTutoring.SET_STEP,
    payload: step
});

export const setFormValues = (data: any) => ({
    type: GiveTutoring.SET_FORM_VALUES,
    payload: data
})
