import GemeindeErstberatung from "../constants/GemeindeErstberatung.constants";
import {Steps} from "../../pages/GemeindeErstberatungFormSteps/Steps";

type actionObject = {
    type: string;
    payload: any;
};

const initialState = {
    step: 1,
    lastActivatedStep: 1,
    data: {

    },
    formValues: {}
};

const GemeindeErstberatungForm = (state = initialState, action: actionObject) => {
    switch (action.type) {
        case GemeindeErstberatung.NEXT:
            if (state.lastActivatedStep === state.step) {
                return {
                    ...state,
                    step: state.step + 1,
                    lastActivatedStep: state.step + 1,
                    direction: "NEXT"
                };
            }
            return { ...state, step: state.step + 1, direction: "NEXT" };

        case GemeindeErstberatung.PREV:
            return { ...state, step: state.step - 1, direction: "PREV" };

        case GemeindeErstberatung.SET_DATA:
            const payload = { ...action.payload };
            return { ...state, data: { ...state.data, ...payload } };

        case GemeindeErstberatung.SET_STEP:
            if (action.payload <= state.lastActivatedStep && state.lastActivatedStep !== Object.keys(Steps).length) {
                return { ...state, step: action.payload };
            }
            return state;

        case GemeindeErstberatung.SET_FORM_VALUES:
            return { ...state, formValues: {...state.formValues, ...action.payload} };

        default:
            return state
    }
};

export default GemeindeErstberatungForm;