import {apiToken, baseUrl} from "./index";
import {EnterJobData} from "../pages/EnterJobFormSteps/EnterJobData";
import {Either} from "fp-ts/es6/Either";
import {Errors} from "io-ts";

const url = baseUrl + "/get-ferialjob-options";
const submitUrl = baseUrl + "/store-ferialjob";

export const fetchEnterJobFormData = async (): Promise<Either<Errors, EnterJobData>> => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': 'Bearer ' + apiToken

        },
    });
    const data = await response.json();

    return EnterJobData.decode(data);
}

export const fetchBezirke = async () => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': 'Bearer ' + apiToken

        },
    });
    const data = await response.json();

    return data.bezirk;
};

export const fetchEinsatzbereich = async () => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': 'Bearer ' + apiToken

        },
    });
    const data = await response.json();

    return data.einsatzbereich;
};

export const fetchZeitraum = async () => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': 'Bearer ' + apiToken

        },
    });
    const data = await response.json();

    return data.zeitraum;
};

export const fetchBeschaftigung = async () => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': 'Bearer ' + apiToken

        },
    });
    const data = await response.json();

    return data.beschaftigung;
};

export const fetchBewerbung = async () => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': 'Bearer ' + apiToken
        },
    });
    const data = await response.json();

    return data.bewerbung;
};

export const submitEnterJobForm = async (data: { [key: string]: any }) => {

    const response = await fetch(submitUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': 'Bearer ' + apiToken
        },

        body: JSON.stringify(data)
    });
    return await response.json();
};

